.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 8px;
  text-decoration: none;
  box-sizing: border-box;

  max-inline-size: 100%;
  min-inline-size: 80px;
}

.wrapper--stretched {
  inline-size: 100%;
  flex-grow: 1;
}

.wrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity .15s ease-out;
  background: var(--tgui--bg_color);
}

.before,
.after {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: var(--tgui--z-index--simple);
}

.spinner.spinner {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: auto;
  background: inherit;
  border-radius: inherit;
  color: var(--tgui--button--spinner-color);
}

.wrapper--s {
  height: 36px;
  min-width: 34px;
  gap: 6px;

  padding: 8px 12px;
  border-radius: 20px;
}

.wrapper--m {
  height: 42px;
  min-width: 42px;
  gap: 8px;
  padding: 8px 14px;
}

.wrapper--l {
  height: 50px;
  gap: 10px;
  padding: 10px 20px;
}

.wrapper--filled {
  --tgui--button--hovered-opacity: .15;
  --tgui--button--spinner-color: var(--tgui--button_text_color);
  color: var(--tgui--button_text_color);
  background: var(--tgui--button_color);
}

.wrapper--bezeled {
  --tgui--button--hovered-opacity: .07;
  --tgui--button--spinner-color: var(--tgui--link_color);
  color: var(--tgui--link_color);
  background: var(--tgui--secondary_fill);
}

.wrapper--plain {
  --tgui--button--hovered-opacity: .03;
  --tgui--button--spinner-color: var(--tgui--plain_foreground);
  color: var(--tgui--link_color);
  background: transparent;
}

.wrapper--gray {
  --tgui--button--hovered-opacity: .5;
  --tgui--button--spinner-color: var(--tgui--plain_foreground);
  color: var(--tgui--plain_foreground);
  background: var(--tgui--plain_background);
}

.wrapper--outline {
  --tgui--button--hovered-opacity: .5;
  --tgui--button--spinner-color: var(--tgui--plain_foreground);
  color: var(--tgui--plain_foreground);
  background: inherit;
  box-shadow: 0 0 0 1px var(--tgui--outline);
}

.wrapper--white {
  --tgui--button--hovered-opacity: .5;
  --tgui--button--spinner-color: var(--tgui--surface_dark);
  background: var(--tgui--white);
}

.wrapper--ios.wrapper--m {
  border-radius: 12px;
}

.wrapper--ios::after {
  content: unset;
}

.wrapper--loading .before,
.wrapper--loading .after,
.wrapper--loading .content {
  opacity: 0;
}

@media (hover: hover) and (pointer: fine) {
  .wrapper:hover::after {
    opacity: var(--tgui--button--hovered-opacity);
  }
}
