.wrapper {
  overflow: hidden;
  position: relative;
  height: 4px;
  border-radius: 2px;
}

.wrapper--base::after {
  content: '';
  position: absolute;
  inset: 0;
  opacity: .4;
  background: var(--tgui--link_color);
}

.progress {
  position: absolute;
  block-size: 100%;
  border-radius: inherit;
  transition: width 0.2s ease;
  background: var(--tgui--link_color);
  z-index: var(--tgui--z-index--simple);
}
