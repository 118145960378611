.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 96%;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  display: flex;
  flex-direction: column;

  outline: none;
  background-color: var(--tgui--bg_color);
  z-index: var(--tgui--z-index--overlay);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
  border-bottom: 1px solid var(--tgui--divider);
}

.body {
  overflow-y: auto;
  padding-bottom: var(--tgui--safe_area_inset_bottom);
}
