.wrapper {
  overflow: hidden;
  position: absolute;
  inset: 0;
  border-radius: inherit;
  transition: background-color .15s ease-out;
}

.wave {
  content: '';
  position: absolute;

  height: 24px;
  width: 24px;
  margin: -12px 0;
  border-radius: 50%;

  background: var(--tgui--outline);
  animation: waveRise .3s cubic-bezier(.3, .3, .5, 1);
  opacity: 0;
}

@keyframes waveRise {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: scale(8);
    opacity: 0;
  }
}
