.wrapper {
  position: absolute;
  block-size: 1px;
  inline-size: 1px;
  padding: 0;
  margin: -1px;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  overflow: hidden;
  border: 0;
  opacity: 0;
}
