.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--tgui--hint_color);
}

.text {
  flex-grow: 1;
  overflow-wrap: anywhere;
}

.icon {
  flex-shrink: 0;
}
