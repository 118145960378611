.wrapper {
  position: relative;
  background-color: var(--tgui--tertiary_bg_color);

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 0 1px var(--tgui--outline);
}

.image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  visibility: hidden;
  border-radius: inherit;
}

.wrapper--loaded .image {
  visibility: visible;
}

.fallback {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
