.wrapper {
  display: flex;
  gap: 9px;
  padding: 12px;
}

.step {
  min-width: 3px;
  height: 3px;
  width: 100%;

  border-radius: 2px;
  background: var(--tgui--tertiary_bg_color);
}

.step--active {
  background: var(--tgui--link_color);
}
