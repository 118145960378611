.section-add {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%; /* Секция занимает всю ширину */
}

.section-button-container {
    display: flex;
    justify-content: center; /* Центрирование кнопки по горизонтали */
    width: 100%; /* Контейнер на всю ширину секции */
}

.section-button {
    width: 100%;
    margin: 10px; /* Отступы вокруг кнопки */
}

.my-input {
    width: 100%;
    margin: 10px; /* Отступы вокруг поля ввода */
}