.wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 0;
  max-inline-size: 100%;
  height: 44px;
  border: none;
  border-radius: inherit;
  background: transparent;
  transition: color 125ms;
  color: var(--tgui--secondary_hint_color);
}

.wrapper--selected {
  color: var(--tgui--link_color);
}
