.wrapper {
  overflow: hidden;

  width: 100%;
  height: 100%;

  padding: 2px;
  box-sizing: border-box;

  border-radius: 44px;
  background: var(--tgui--tertiary_bg_color);
}

.body {
  position: relative;
  display: flex;
  align-items: center;
  align-content: stretch;

  width: 100%;
  height: 100%;

  box-sizing: border-box;
  border-radius: inherit;
}

.slider {
  position: absolute;
  inset: 0;
  transition: transform 150ms;
  border-radius: 40px;
  z-index: var(--tgui--z-index--simple);
  box-sizing: border-box;
  background: var(--tgui--segmented_control_active_bg);
}

.wrapper--ios {
  border-radius: 9px;
  background: var(--tgui--tertiary_bg_color);
}

.wrapper--ios .slider {
  border: var(--tgui--border--width) solid rgba(0, 0, 0, .04);
  border-radius: inherit;
  box-shadow:
      0 3px 1px 0 rgba(0, 0, 0, .04),
      0 3px 8px 0 rgba(0, 0, 0, .12);
}
