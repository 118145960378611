.wrapper {
  position: relative;
}

.select {
  appearance: none;

  padding: 12px 58px 12px 16px;

  width: 100%;
  border: none;

  color: var(--tgui--text_color);
  outline: none;
  border-radius: inherit;
  background: inherit;
}

.chevron {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: var(--tgui--secondary_hint_color);
  pointer-events: none;
}

