.wrapper {
  position: absolute;
}

.icon {
  content: '';
  display: block;
  transform: translateY(1px);
}

.wrapper--placement-right {
  transform: rotate(90deg) translate(50%, -50%);
  transform-origin: right;
}

.wrapper--placement-bottom {
  transform: rotate(180deg);
}

.wrapper--placement-left {
  transform: rotate(-90deg) translate(-50%, -50%);
  transform-origin: left;
}
